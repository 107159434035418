import React from 'react';
import './Hoc.scss';
import Header from './Header';

type Props = {
	children: any;
}

const Hoc = ({ children }: Props) => (
	<div
		className="hoc-container"
	>
		<Header />
		<div className="content">
			{children}
		</div>
		<div className="footer"/>
	</div>
);

export default Hoc;
