import React from 'react';
import './Origins.scss';
import Hoc from '../../components/Hoc';

const Origins = () => (
	<Hoc>
		<div
			className="origins-container"
		>
			<h1>Origins</h1>
			<p>Origins of lord cho go here</p>
		</div>
	</Hoc>
);

export default Origins;
