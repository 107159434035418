import React, { useState } from 'react';
import './HomePage.scss';
import Hoc from '../../components/Hoc';

const HomePage = () => {
	const [clicks, setClicks] = useState<number>(0);
	return (
		<Hoc>
			<div
				className={`homepage-container ${clicks > 10 ? 'rotating' : ''}`}
			>
				<h1>ChoNation</h1>
				<p dangerouslySetInnerHTML={{ __html: 'A BDONA and<br/>BDO mobile<br/>Guild' }} />
				{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
				<img
					onClick={() => {
						setClicks(clicks + 1);
					}}
					src="/img/cho.jpg"
					alt="cho"
				/>
				<p><b>Recruiting the strongest strong people</b></p>
				<a
					href="https://discordapp.com/invite/chogger2"
					target="_blank"
					rel="noopener noreferrer"
				>
					<img
						className="discord-icon"
						alt="discord"
						src="/img/discord.png"
					/>
				</a>
			</div>
		</Hoc>
	);
};

export default HomePage;
