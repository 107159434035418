import React from 'react';
import { AnimatedSwitch } from 'react-router-transition';

import {
	BrowserRouter as Router,
	Route,
} from 'react-router-dom';
import HomePage from './HomePage/HomePage';
import Origins from './Origins/Origins';
import Games from './Games/Games';
import './Router.scss';
import Testimonials from './Testimonials/Testimonials';

const MainRouter = () => (
	<Router>
		<AnimatedSwitch
			atEnter={{ opacity: 0 }}
			atLeave={{ opacity: 0 }}
			atActive={{ opacity: 1 }}
			className="switch-wrapper"
		>
			<Route exact path="/" component={HomePage} />
			<Route path="/Origins" component={Origins} />
			<Route path="/Games" component={Games} />
			<Route path="/Testimonials" component={Testimonials} />
		</AnimatedSwitch>
	</Router>
);

export default MainRouter;
