import React from 'react';
import { useHistory } from 'react-router-dom';
import './Header.scss';

const Header = () => {
	const history = useHistory();

	const onClickOrigins = () => history.push('/Origins');
	const onClickHome = () => history.push('/');
	const onClickGames = () => history.push('/Games');
	const onClickVideos = () => window.open('https://www.youtube.com/watch?v=j3-QSBcuWJk&list=PLpRBWNJRuxR4SQ3GVxuGjxDHbnaSyYpEB');
	const onClickTestimonials = () => history.push('/Testimonials');

	const buttons = [
		{
			name: 'Videos',
			onClick: onClickVideos,
		},
		{
			name: 'Home',
			onClick: onClickHome,
		},
		// {
		// 	name: 'Origins',
		// 	onClick: onClickOrigins,
		// },
		{
			name: 'Games',
			onClick: onClickGames,
		},
		// {
		// 	name: 'Testimonials',
		// 	onClick: onClickTestimonials,
		// },
	];

	return (
		<div className="header-container">
			{
				buttons.map((button) => (
					<button
						onClick={button.onClick}
						type="button"
					>
						{button.name}
					</button>
				))
			}
		</div>
	);
};

export default Header;
