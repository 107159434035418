import React from 'react';
import MainRouter from "./features/Router";

function App() {
  return (
    <MainRouter/>
  );
}

export default App;
