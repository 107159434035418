import React from 'react';
import './Games.scss';
import GamesItem from './components/GamesItem';
// eslint-disable-next-line no-unused-vars
import { GameItem } from '../../models/GameItem';
import Hoc from '../../components/Hoc';

const games: GameItem[] = [
	{
		name: 'FML Game',
		description: 'Lord Cho leads a Choly Crusade against the evil FML devils',
		url: 'https://fmlgame.chonation.moe',
		imgSrc: '/img/fmlgame.png',
	},
	{
		name: 'Barcode Game',
		description: 'Lord Cho defends his castle against communist Barcode invaders',
		url: 'https://barcodegame.chonation.moe',
		imgSrc: '/img/barcodegame.png',
	},
	{
		name: 'Blackrose Game',
		description: 'Lord Cho goes to outer space to stop the Alien Blackrose assault',
		url: 'https://blackrosegame.chonation.moe',
		imgSrc: '/img/blackrosegame.png',
	},
];
const Games = () => (
	<Hoc>
		<div
			className="games-container"
		>
			{
				games.map((game) => (
					<GamesItem
						url={game.url}
						imgSrc={game.imgSrc}
						name={game.name}
						description={game.description}
					/>
				))
			}
		</div>
	</Hoc>

);

export default Games;
