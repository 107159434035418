import React from 'react';
import './Testimonials.scss';
import Hoc from '../../components/Hoc';

const images = [
	'https://i.imgur.com/2SyfnJ3.png',
	'https://i.imgur.com/Sfo1O6i.png',
];

const Testimonials = () => (
	<Hoc>
		<div className="testimonials-container">
			<blockquote className="imgur-embed-pub" lang="en" data-id="a/2Kq2HKC" data-context="false">
				{/* eslint-disable-next-line max-len */}
				{/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label */}
				<a
					href="//imgur.com/a/2Kq2HKC"
				/>
			</blockquote>
			<script async src="//s.imgur.com/min/embed.js" charSet="utf-8" />
		</div>
	</Hoc>
);

export default Testimonials;
