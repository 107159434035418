import React from 'react';
import './GamesItem.scss';

type Props = {
	url: string;
	imgSrc: string;
	name: string;
	description: string;
}

const GamesItem = ({
	url, name, description, imgSrc,
}:Props) => (
	<div
		className="game-item-container"
	>
		<a
			href={url}
			target="_blank"
			rel="noopener noreferrer"
		>
			<img
				alt="game-banner"
				src={imgSrc}
			/>
		</a>

		<h1>{name}</h1>
		<p>{description}</p>
	</div>
);


export default GamesItem;
